<template>
	<div class="wrapper">
		<v-header-admin @mobileActive="mobileActive"></v-header-admin>

		<router-view />

		<v-footer-admin></v-footer-admin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Index',
	data() {
		return {
			mobileStatus: false,
		}
	},
	computed: {
		...mapGetters(['_getUserObject']),
	},
	methods: {
		mobileActive(status) {
			this.mobileStatus = status
		},
	},
}
</script>
